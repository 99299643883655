/* eslint-disable max-len */
const Apps = {
  items: [
    {
      sva: 'Aya Audiobooks Carros',
      description: 'Aplicativo de audiolivros que possibilita ouvir em qualquer lugar, otimizando tempo e multiplicando conhecimento. Todo mês é disponibilizado um ou dois audiolivros de sucesso, de acordo com a oferta.',
      icon: 'aya',
      subDescription: [],
      plan: 'A partir do TIM Carro Conectado 5GB',
    },
    {
      sva: 'PlayKids',
      description:
        'Playkids é um dos melhores aplicativos de desenhos e jogos educativos para a criançada aprender brincando. Centenas dos melhores desenhos animados, videoclipes musicais e programas de TV de alta qualidade para crianças e bebês. Os desenhos favoritos da criançada em um só lugar: Turma da Mônica, Galinha Pintadinha, Palavra Cantada, Meu AmigãoZão, Patati Patatá, Mundo de Bita e muitos outros.',
      icon: 'playkids',
      subDescription: [],
      plan: 'A partir do TIM Carro Conectado 40GB'
    },
    {
      sva: 'Ola Podcasts',
      description: 'Ouça os melhores podcasts, inclua os seus favoritos e tenha liberdade para criar o seu!      ',
      icon: 'ola',
      subDescription: [
        {
          title: 'OLA Podcasts light',
          content: 'os assinantes, além de não serem impactados por propagandas, poderão criar 10 playlists, salvar até 40 episódios simultâneos para ouvir offline e criar até 05 canais próprios.'
        },
        {
          title: 'OLA Podcasts full',
          content: 'assinantes além de não serem impactados por propagandas, poderão criar playlists e canais próprios ilimitadas, além de ouvir episódios offline de forma ilimitada.'
        }
      ],
      plan: 'A partir do TIM Carro Conectado 10GB',
    },
    {
      sva: 'Waze',
      description: 'Acesso ilimitado ao Waze e tenha sempre detalhes sobre suas rotas em tempo real.',
      icon: 'waze',
      subDescription: [],
      plan: 'A partir do TIM Carro Conectado 10GB',
    },
  ],
};

export default Apps;

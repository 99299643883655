import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import * as UFsService from '../../shared/services/ufs';
import * as DataService from '../../shared/services/data';
import Apps from '../../shared/services/svas/mocks/carrosconectados/apps';
import { mobile } from '../../shared/styles/media-queries';

import {
  TopBar,
  UFSelector,
  CardCarros,
  AppsCarros,
  Loader,
  BannerCarros,
  BannerFuncionamento,
  LgpdBanner,
  Footer,
  FooterFixed,
  Faq,
  CoverageMap,
} from '../../shared/components';

export default function CarroConectadoPage() {
  const [isUFSelectorVisible, toggleUFSelector] = useState(false);
  const [uf, changeUF] = useState('');
  const [isLoading, changeLoading] = useState(true);
  const [plans, changePlans] = useState([]);

  useEffect(() => {
    const uf = UFsService.getUFByParams() || '';
    loadData(uf);
  }, []);

  const handleChangeUF = e => {
    let uf = e.target.value;
    uf === 'DF' ? (uf = 'GO') : uf;
    loadData(uf);
  };

  const loadData = async uf => {
    changeLoading(true);
    const plans = await DataService.getCarrosConectadosData(uf);
    changeUF(uf || plans[0].skuCode.substring(7, 9));
    changePlans(plans);
    changeLoading(false);
  };


  const handleCloseUFSelector = () => {
    toggleUFSelector(false);
  };

  return (
    <>
      <Helmet>
        <script>
          {`
            (function(s,t,a,n){s[t] || (s[t] = a,n = s[a] = function() {n.q.push(arguments);},
            n.q = [],n.v = 2,n.l = 1 * new Date)})(window,"InstanaEumObject","ineum");
            ineum('reportingUrl', 'https://eum-orange-saas.instana.io');
            ineum('key', 'AdfR0MyqRLatm8g8jY5x1g');
            ineum('trackSessions')
          `}
        </script>
        <script defer crossOrigin="anonymous" src="https://eum.instana.io/eum.min.js"></script>
      </Helmet>
      <TopBar uf={uf} onUFRequest={() => toggleUFSelector(true)} noBanner={true} />
      <BannerCarros />
      <Container>
        <PlansContainer>{plans && plans.map((plan, key) => <CardCarros plan={plan} uf={uf} key={key} />)}</PlansContainer>
        <PlansDescription>
          <strong>*Experimente grátis por 30 dias a oferta TIM Carro Conectado.</strong> Após esse período grátis você será cobrado
          automaticamente o valor do plano contratado. Os 30 dias grátis são válidos para contratação uma única vez por CPF/CNPJ por
          veículo.
        </PlansDescription>
        <AppsCarros data={Apps} />
      </Container>
      <BannerFuncionamento />
      <Faq />
      <FooterFixed />
      <Footer template="-carro-conectado" />
      <UFSelector
        visible={isUFSelectorVisible}
        handleCloseUFSelector={handleCloseUFSelector}
        selectedUF={uf}
        handleChangeSelect={e => handleChangeUF(e)}
        ufs={UFsService.getUFs()}
      />
      <LgpdBanner />
      <CoverageMap />
      <Loader isLoading={isLoading} />
    </>
  );
}

const Container = styled.section`
  margin: 0 auto;
  max-width: 1170px;
  padding: 0 15px;
  position: relative;
  width: 100%;
  margin-top: -70px;
`;

const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  ${mobile} {
    flex-direction: column;
  }
`;

const PlansDescription = styled.p`
  color: #004691;
  display: block;
  font-size: 14px;
  margin: 35px auto 60px;
  padding: 0 155px;
  text-align: center;

  ${mobile} {
    padding: 0 20px;
  }
`;
